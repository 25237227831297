import React from "react";
import Prism from "prismjs";
import Image from "gatsby-image";
import {
  SiCss3,
  SiHtml5,
  SiJavascript,
  SiJson,
  SiTypescript,
} from "react-icons/si";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { ContentfulPost } from "../../types";
import Styled from "./styles";
import "../../styles/prism.css";

interface ArticleContentProps extends ContentfulPost {}

const fileTypes = {
  js: <SiJavascript />,
  ts: <SiTypescript />,
  html: <SiHtml5 />,
  css: <SiCss3 />,
  json: <SiJson />,
};

const ArticleContent: React.FC<ArticleContentProps> = ({ title, content }) => {
  if (!content) return null;

  const { raw, references } = content;

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entry = references.find(
          (reference) => reference.contentful_id === node.data?.target?.sys?.id
        );

        if (!entry) return null;

        const code = Prism.highlight(
          entry.code.code,
          Prism.languages.javascript,
          "javascript"
        );

        return (
          <div className="code-block">
            {entry.filePath && (
              <span className="filename">
                <div className="inner">
                  {fileTypes[entry.fileType]}
                  {entry.filePath}
                </div>
              </span>
            )}
            <pre className="language-javascript">
              <code dangerouslySetInnerHTML={{ __html: code }} />
            </pre>
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const entry = references.find(
          (reference) => reference.contentful_id === node.data?.target?.sys?.id
        );

        if (!entry) return null;

        const { description, fluid } = entry;

        return (
          <figure>
            <Image fluid={fluid} />
            {description && <figcaption>{description}</figcaption>}
          </figure>
        );
      },
    },
  };

  return (
    <Styled.ArticleContent>
      <h1>{title}</h1>
      {content && (
        <div className="content">
          {documentToReactComponents(JSON.parse(raw), options)}
        </div>
      )}
    </Styled.ArticleContent>
  );
};

export default ArticleContent;
