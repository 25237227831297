import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../layout";
import Container from "../components/Container";
import ArticleContent from "../components/ArticleContent";
import Metadata from "../components/Metadata";
import { ContentfulPost } from "../types";

interface ContentfulPostProps extends PageProps {
  data: {
    post: ContentfulPost;
  };
  pathContext: any;
}

export const query = graphql`
  query PostQuery($contentful_id: String!) {
    post: contentfulPost(contentful_id: { eq: $contentful_id }) {
      contentful_id
      title
      slug
      date
      tags {
        title
      }
      content {
        raw
        references {
          ...CodeBlock
          ...Asset
        }
      }
      metadata {
        ...Metadata
      }
    }
  }

  fragment CodeBlock on ContentfulCodeBlock {
    contentful_id
    filePath
    fileType
    code {
      code
    }
  }

  fragment Asset on ContentfulAsset {
    contentful_id
    description
    fluid {
      ...GatsbyContentfulFluid_withWebp
    }
    file {
      url
    }
  }
`;

const PostTemplate: React.FC<ContentfulPostProps> = ({ data = {} }) => {
  const { post } = data;

  return (
    <Layout>
      <Metadata {...(post.metadata ? post.metadata : {})} />
      <Container width="narrow">
        <ArticleContent {...post} />
      </Container>
    </Layout>
  );
};

export default PostTemplate;
