import styled from "styled-components";

const ArticleContent = styled.article`
  position: relative;
  padding: 100px 0;

  @media screen and (max-width: 750px) {
    padding: 50px 0;
  }

  h1 {
    font-size: 50px;
    font-family: "Roboto Slab", serif;

    @media screen and (max-width: 750px) {
      font-size: 30px;
    }
  }

  .code-block {
    padding: 20px 0;
  }

  figure {
    margin: 50px 0;

    img {
      max-width: 100%;
      border: 1px solid #666;
    }

    figcaption {
      align-items: center;
      color: #333;
      font-size: 16px;
      padding: 10px 10px 10px 15px;
      border-left: 5px solid #d1503f;
      background-color: #f3f3f3;
    }
  }

  .filename {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #666;
    display: inline-block;
    font-size: 14px;
    padding: 5px 20px 5px 15px;
    color: #333;
    background-color: #333;
    font-family: "Courier New", Courier, monospace;

    .inner {
      display: flex;
      align-items: center;
      color: #fff;

      svg {
        margin-right: 10px;
      }
    }
  }

  hr {
    background-color: #999;
    border: none;
    height: 1px;
    margin: 50px 0;
  }

  a {
    color: #d1503f;
  }

  .language-javascript {
    margin-top: 0;
  }

  .content {
    font-size: 16px;
    line-height: 1.75;

    > p > code {
      background: #2d2d2d;
      padding: 5px 10px;
      color: #ccc;
    }
  }

  ul {
    u {
      background-color: #f3f3f3;
      font-size: 15px;
      padding: 5px;
      text-decoration: none;
    }
  }

  h6 {
    border-left: 2px solid #999;
    padding: 5px 0 5px 10px;
    font-size: 16px;
    font-weight: normal;
    color: #666;

    i {
      color: #d1503f;
    }

    u {
      text-decoration: none;
      background-color: #eee;
      padding: 5px 10px;
    }
  }
`;

export default {
  ArticleContent,
};
